/* Dark Mode */
:root {
  --bg-color: #ffffff;
  --fg-color: #fafafa;
  --text-color: #333;
  --label-color: #555;
  --label-text-color: #fafafa;
  --border-color: #ddd;
}

.dark-mode {
  --bg-color: #282828;
  --fg-color: #3f3f3f;
  --text-color: #ffffff;
  --label-color: #aaa;
  --label-text-color: #333;
  --border-color: #333;
}

h1, h2, h3, h4, h5, p, a, img {
  color: var(--text-color);
  margin-top: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text-color);
  background-color: var(--bg-color);
  transition: background-color 0.5s ease, color 0.5s ease;
  /* font-family: Arial, sans-serif; */
  color: #333;
}


/* Scroll Behavior */
body, html {
  height: 100%;
  margin: 0;
  scroll-behavior: smooth; /* Smooth scroll between sections */
}

.App {
  height: 100vh;
  margin: 0 auto;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

#content {
  overflow-y: auto;
  scroll-snap-type: y mandatory; /* Enable snapping in vertical direction */
  display: flex;
  flex-direction: column;
}

section {
  scroll-snap-align: start; /* Snap to the start of each section */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: auto;
  box-sizing: border-box;
}

section > div {
  min-height: 100vh;
  width: 100%;
  max-width: 1200px; /* Optional: limit the content width */
  display: flex;
  padding: 75px 20px 75px;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  flex-grow: 1; /* Allows the div to grow as needed */
}
/* Padding */
.details {
  padding: 20px 10vw
}

section ul {
  list-style-type: none;
  padding: 0;
}

section ul li {
  margin-bottom: 10px;
}

h1 {
  font-size: 2.5em;
  margin-bottom: 10px;
}

h2 {
  font-size: 2em;
  margin-bottom: 15px;
}

h3 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

h4 {
  font-size: 1.2em;
  margin-bottom: 10px;
}

h5 {
  font-size: 1.2em;
  margin: 0px;
}

/* Navigation Bar */
nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: var(--fg-color);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  margin-bottom: 50px;
}

nav ul {
  display: flex;
  justify-content: center;
  padding: 10px 0;
  margin: 0;
}

nav ul li {
  margin: 0 15px;
  color: var(--text-color);
}

nav ul li a {
  cursor: pointer;
  text-decoration: none;
  font-size: 1.2em;
}

nav ul li a:hover {
  color: #1a73e8;
}

/* Personal Info */
#personal-info {
  text-align: center;
}

#personal-info h1 {
  margin-bottom: 5px;
}

#personal-info p {
  font-size: 1.1em;
  color: var(--text-color);
}

#personal-info ul {
  margin-top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

#personal-info ul li{
  flex-direction: row;
  padding: 5px;
}

#personal-info img {
  width: 25px;
  height: auto;
}

#personal-info a {
  color: var(--text-color);
}

/* Social Media */
#socials {
  text-align: center;
}

#socials ul {
  padding: 0;
}

#socials li {
  display: inline;
  margin: 0 10px;
}

/* Education */
#education {
  border-top: 1px solid var(--border-color);
  margin-bottom: 30vh;
}

#education .details {
  background: var(--fg-color);
  border-radius: 5px;
}

#education .favorite-classes {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 15px;
}

/* Work Experience */
#work-experience {
  border-top: 1px solid var(--border-color);
  margin-bottom: 40vh;
}

#work-experience .details {
  background: var(--fg-color);
  border-radius: 5px;
  margin-bottom: 20px;
}

#work-experience .job-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#work-experience .job-header h3 {
  margin: 0;
  font-weight: bold;
}

#work-experience .job-header .company {
  font-size: 1.2em;
}

#work-experience .job-header .city {
  font-style: italic;
  font-size: 1em;
  color: #777;
}

#work-experience .job-details p {
  margin: 5px 0;
}

.skills-used {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  justify-content: center;
  flex-wrap: wrap;
}

/* Skills */
.skills {
  border-top: 1px solid var(--border-color);
  /* padding-top: 20px; */
  text-align: center;
}

.skills ul {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.skills img, .skills svg {
  width: 60px;
  height: 60px;
  border-radius: 20%;
}

.skills li  {
  margin: 10px;
  position: relative;
  display: inline-block;
  transition: transform 0.2s;

}

.skills li:hover {
  transform: scale(1.1);
}

.skills li::after {
  content: attr(data-skill-name);
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%); 
  white-space: nowrap;
  background: var(--label-color);
  color: var(--label-text-color);
  padding: 5px 10px;
  border-radius: 5px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.25s, visibility 0.25s;
  font-size: 0.9em;
}

.skills li:hover::after {
  opacity: 1;
  visibility: visible;
}


/* Projects */
#projects {
  border-top: 1px solid var(--border-color);
  margin-bottom: 40vh;
}

#projects .details {
  background: var(--fg-color);
  border-radius: 5px;
  margin-bottom: 20px;
}

#projects .project-header{
  /* font-size: 1.5em; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin: 0;
}
#projects .project-header svg{
  font-size: 1.5em;
  padding-bottom: 5px;
}

#projects .details p {
  margin: 5px 0;
}

.code {
  margin-bottom: 0;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.fun-text {
  display: inline-block;
  position: relative;
  user-select: none;
  color: var(--text-color);
}

.fun-mode-letter {
  display: inline-block;
  transition: transform 0.3s ease;
}

.fun-mode-active .fun-mode-letter.exploded {
  transform: translate(var(--dx, 0), var(--dy, 0)) rotate(var(--dΘ, 0));
}

.buttons-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 10px;
}


.buttons-menu button {
  background: none;
  border: 1px solid var(--text-color);
  border-radius: 5px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
  
@keyframes cursorPulse {
  0%, 10% { cursor: crosshair; }
  10%, 20% { cursor: help; }
  20%, 30% { cursor: move; }
  30%, 40% { cursor: zoom-in; }
  40%, 50% { cursor: grab; }
  50%, 60% { cursor: wait; }
  60%, 70% { cursor: text; }
  70%, 80% { cursor: not-allowed; }
  80%, 90% { cursor: pointer; }
  90%, 100% { cursor: progress; }
}

.buttons-menu button:hover {
  animation: cursorPulse 4s infinite;
}